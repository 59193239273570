<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】报价计算方式' : (todo == 'edit' ? '【修改】报价计算方式' : '【查看】报价计算方式')"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="计算方式名称" prop="calculationName">
              <el-input v-model="dataForm.calculationName" placeholder="计算方式名称" :disabled="todo != 'view' ? false : true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计算方式代码" prop="calculationCode">
              <el-input v-model="dataForm.calculationCode"  placeholder="计算方式代码" :disabled="todo != 'view' ? false : true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="订单类型" prop="orderType">
              <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" :disabled="todo != 'view' ? false : true" filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.orderType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="描述" prop="describe">
              <el-input type="textarea" :rows="5" v-model="dataForm.describe" :disabled="todo != 'view' ? false : true" placeholder="描述"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="dataForm.remark" :disabled="todo != 'view' ? false : true" placeholder="备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        calculationCode: null,
        calculationName: null,
        orderType: null,
        describe: null,
        remark: null
      },
      todo: 'view',
      dataRule: {
        calculationName: [
          { required: true, message: '计算方式名称不能为空', trigger: 'blur' }
        ],
        calculationCode: [
          { required: true, message: '计算方式代码不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/priceCalculation/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.calculationName = data.calculationName
            this.dataForm.orderType = data.orderType
            this.dataForm.calculationCode = data.calculationCode
            this.dataForm.describe = data.describe
            this.dataForm.remark = data.remark
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/priceCalculation/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'calculationName': this.dataForm.calculationName,
              'calculationCode': this.dataForm.calculationCode,
              'orderType': this.dataForm.orderType,
              'describe': this.dataForm.describe,
              'remark': this.dataForm.remark
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
